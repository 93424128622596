import { gql } from '@apollo/client';

export const SET_NEW_USER_PASSWORD=gql`
mutation setNewUserPassword($token:String!,$newPassword:String!){
    setNewUserPassword(input:{token:$token,newPassword:$newPassword}){
      status,
      message,
      errorCode
    }
}
`
