import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentStatusKeys = {
  success: 'success',
  cancel: 'cancel',
};

const QueryParams = {
  status: 'status',
  id: 'token',
};
export const PaymentStatus = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const isSuccess = query.get(QueryParams.status) === PaymentStatusKeys.success;
  const id = query.get(QueryParams.id);

  return (
    <div className="container paypal-payment-status">
      <div className="text-center d-flex justify-center">
        {isSuccess ? (
          <img src="/MarketplaceIcon.svg" alt="marketplace-icon" />
        ) : (
          <img src="/Error.svg" alt="marketplace-icon" />
        )}
      </div>

      {isSuccess ? (
        <p className="title">Payment successful</p>
      ) : (
        <p className="title">We’re Sorry</p>
      )}

      {isSuccess ? (
        <p className="subtitle">Your payment Id is #{id}.</p>
      ) : (
        <p className="subtitle">
          Your payment was unsuccessful, please try again.
        </p>
      )}

      <div className="container button-container p-4">
        <div className="row mt-4">
          <button
            className="btn btn-outline-secondary"
            id="back-to-marketplace"
          >
            Back to the marketplace
          </button>
        </div>
      </div>
    </div>
  );
};
