import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Errorm } from "./Errorm";
import { useState } from "react";
import {useMutation} from "@apollo/client";
import { SET_NEW_USER_PASSWORD } from "../apollo/Mutation";
import { useParams } from "react-router-dom";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  newPassword: yup
    .string()
    .required("Please confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const ResetPassword = () => {
  const { token } = useParams();

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    showPassword2: false,
  });
  const [successfullyReset, setSuccessfullyReset] = useState(false);

  const [setNewPassword, { data, loading, error }] = useMutation(SET_NEW_USER_PASSWORD); //*it takes 2 values
  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  //!password visibility (eye button)==========>
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  //!======================================<

  return (
    <>
      <div className="innerBox h-full border border-black  justify-center ">
        {data && data.user && (
          <div className="data">
            {data.user.newPassword} Your Password changed successfully
          </div>
        )}
        <div
          className="inner w-96  bg-white p-3 m-auto 
                drop-shadow-2xl
                border border-gray-200  items-center justify-center my-20 rounded-xl"
        >
          <div className="heading text-2xl font-extrabold mt-6 mb-2">
           {successfullyReset ? "Your Password changed successfully." : "Forgot Password"}
          </div>

          {!successfullyReset && <Formik
            validationSchema={validationSchema}
            initialValues={{ password: "", newPassword: "" }}
            onSubmit={(values) => {
              setNewPassword({
                variables: { token, newPassword: values.newPassword },
                onCompleted: () => {
                  setSuccessfullyReset(true);
                }
              });
            }}
          >
            <Form>
              <Field
                className="inputBox w-64 border border-black rounded-md  my-4 mt-5 sticky p-3"
                placeholder="Enter Password"
                name="password"
                type={values.showPassword ? "text" : "password"}
              />
              <i
                className="fa-solid fa-eye relative -ml-6"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword1}
              ></i>
              <br />
              <Errorm color name="password" />

              <Field
                className="inputBox w-64 border border-black rounded-md my-2 mt-5 p-3"
                placeholder="Confirm Password"
                name="newPassword"
                type={values.showPassword2 ? "text" : "password"}
              />
              <i
                className="fa-solid fa-eye relative -ml-6"
                onClick={handleClickShowPassword2}
                onMouseDown={handleMouseDownPassword2}
              ></i>

              <br />
              <Errorm color name="newPassword" className="h-3 border-2" />

              <button
                className="submit w-64  border-2 bg-teal-500 rounded-md my-3 mb-10
                            hover:bg-teal-600 mt-10 p-3 font-bold text-2xl text-white"
                type="Submit"
              >
                Submit
              </button>
            </Form>
          </Formik>}
        </div>
      </div>
    </>
  );
};
