import { Routes, Route } from "react-router-dom";
import "./App.css";
import { PaymentStatus } from "./components/PaymentStatus";
import { ResetPassword } from "./components/Reset-Password";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/paymentStatus" element={<PaymentStatus />}></Route>
        <Route path="/reset-password/:token" element={<ResetPassword />}></Route>
      </Routes>
    </div>
  );
}

export default App;
