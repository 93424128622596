import { ErrorMessage } from 'formik'
import React from 'react'

export const Errorm = ({name}) => {
  return (
    <div  className="text-red-500 text-sm "
    >
        <div>
           <ErrorMessage name={name}/>
        </div>
    </div>
  )
}
